import React from 'react';
import IProductPage from './Models/ProductPageModel.interface';
import useCurrentPage from '../Shared/Hooks/useCurrentPage';
import { styled, theme } from '../Theme';
import ContentContainer from '../Shared/ContentContainer/ContentContainer';
import AttributeArea from '../ProductPage/AttributeArea';
import ProductImagesContainer from './ProductImagesContainer';
import { media } from '@glitz/core';
import Breadcrumb from '../Shared/Breadcrumb/Breadcrumb';
import KexLink from '../Shared/KexLink/KexLink';
import ArrowLeftIconPrimary from '../Shared/Icons/ArrowLeftIconPrimary';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';

function ProductPage() {
  const productPageData = useCurrentPage<IProductPage>();
  const {
    translations: { 'productPage/backToPlanogram': backToPlanogramLabel },
    placeholderImages: { productPlaceholder: productPlaceholderImg },
  } = useAppSettingsData();
  const variation = productPageData.variationCollection[0];
  const {
    sliderImages,
    code,
    breadcrumb,
    planogramUrl,
    bookingNumber,
    inputQuantity,
  } = productPageData;

  return (
    <main>
      {!planogramUrl ? (
        !!breadcrumb.length && <Breadcrumb breadcrumb={breadcrumb} />
      ) : (
        <BackLinkWrapper>
          <ArrowLeftIcon />
          <BackLink href={planogramUrl}>{backToPlanogramLabel}</BackLink>
        </BackLinkWrapper>
      )}
      <Section>
        <ContentContainer>
          <Top>
            {sliderImages && !!sliderImages.length ? (
              <ProductImage>
                <ProductImagesContainer key={code} image={sliderImages[0]} />
              </ProductImage>
            ) : (
              <ModalProductImage
                src={productPlaceholderImg?.src}
              ></ModalProductImage>
            )}
            <AttributeArea
              key={code}
              productPage={productPageData}
              variation={variation}
              bookingNumber={bookingNumber}
              planogramInputQuantity={inputQuantity}
            />
          </Top>
        </ContentContainer>
      </Section>
    </main>
  );
}

const BackLinkWrapper = styled.div({
  position: 'absolute',
  top: theme.desktopHeaderHeight,
  left: 0,
  right: 0,
  margin: { x: 'auto' },
  maxWidth: theme.screenMaxWidth,
  width: '100%',
  paddingTop: '18px',
  alignItems: 'center',
  display: 'flex',
  paddingLeft: '32px',
  lineHeight: 1.33,
  letterSpacing: 0.45,
});

const ArrowLeftIcon = styled(ArrowLeftIconPrimary, {
  fill: theme.linkColor,
  marginRight: theme.spacing(4),
});

const BackLink = styled(KexLink, {
  fontSize: theme.gamma,
  color: theme.linkColor,
  cursor: 'pointer',
  textDecoration: 'underline',
});

const Section = styled.section({
  backgroundColor: theme.primaryBackground,
  position: 'relative',
  maxWidth: '100%',
  margin: { top: theme.spacing(16), bottom: theme.spacing(16) },
  padding: { x: theme.large },
  ...media(theme.mediaQuery.mediaMinSmall, {
    padding: { x: theme.great },
  }),
  ...media(theme.mediaQuery.mediaMinLarge, {
    margin: { top: theme.spacing(13), bottom: theme.spacing(32) },
  }),
});

const Top = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridGap: theme.none,
  width: '100%',
  ...media(theme.mediaQuery.mediaMinHuge, {
    gridTemplateColumns: '1fr 1fr',
  }),
  ...media(theme.mediaQuery.mediaMinLarge, {
    gridTemplateColumns: '1fr 1fr',
    gridGap: theme.massive,
  }),
});

const ProductImage = styled.div({
  maxWidth: '100%',
  ...media(theme.mediaQuery.mediaMinMedium, {
    gridColumn: {
      start: 1,
      end: 3,
    },
  }),
  ...media(theme.mediaQuery.mediaMinLarge, {
    gridColumn: {
      start: 1,
      end: 1,
    },
  }),
});

const ModalProductImage = styled.img({
  height: '100%',
  width: '100%',
  objectFit: 'contain',
});

export default ProductPage;
