import React, { useState } from 'react';
import { styled, theme } from '../Theme';
import ImageModel from '../Assets/Models/ImageModel.interface';
import useMedia from '../Shared/Hooks/useMedia';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import { pseudo, media } from '@glitz/core';
import Modal from '../Shared/Modal/Modal';
import ModalImage from '../Shared/Modal/Content/Image';
import { StyleOrStyleArray } from '@glitz/type';

type ProductImages = {
  image: ImageModel;
};

function ProductImagesContainer({ image }: ProductImages) {
  const [showModal, toggleModal] = useState(false);

  const {
    translations: { 'productPage/close': closeLabel },
  } = useAppSettingsData();

  const isSmall = useMedia(theme.mediaQuery.mediaMaxSmall);

  return (
    <MainImageDiv>
      {/* {!isSmall && images.length > 1 && (
        <ThumbnailContainer>
          {images.map((image, index) => (
            <Thumbnail key={index} onClick={() => setMainImage(image)}>
              <ThumbnailImage
                src={image.src}
                css={mainImage === image ? Selected : {}}
              />
            </Thumbnail>
          ))}
        </ThumbnailContainer>
      )}
      {showModal && (
        <Modal
          overlay={true}
          toggle={() => toggleModal(false)}
          closeLable={closeLabel}
        >
          <ModalImage image={mainImage} />
        </Modal>
      )} */}
      <MainImage
        src={image.src}
        alt={image.alt}
        onClick={() => toggleModal(true)}
      />
    </MainImageDiv>
  );
}

const Selected: StyleOrStyleArray = {
  border: {
    xy: {
      color: theme.black,
    },
  },
};

const MainImageDiv = styled.div({
  position: 'relative',
  display: 'flex',
  padding: {
    xy: theme.gamma,
  },
  paddingBottom: 0,
  filter: theme.shadows.productImageShadow,
  margin: { x: `-${theme.gamma}` },
  ...media(theme.mediaQuery.mediaMinLarge, {
    margin: { x: theme.none },
  }),
});

const MainImage = styled.img({
  width: '100%',
  maxHeight: theme.productImageMaxHeight,
  objectFit: 'contain',
  ...pseudo(':hover', {
    cursor: 'pointer',
  }),
  ...media(theme.mediaQuery.mediaMinMedium, {
    maxHeight: theme.productImageMaxHeight,
  }),
});

const ThumbnailContainer = styled.div({
  height: 'auto',
  width: 'auto',
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '13px',
});

const ThumbnailImage = styled.img({
  maxHeight: theme.thumbnailImageHeight,
  width: 'auto',
  transition: {
    property: 'all',
    duration: theme.timings.threeTenths,
    timingFunction: 'linear',
  },
  ':hover': {
    opacity: 0.6,
  },
});

const Thumbnail = styled.button({
  backgroundColor: 'transparent',
  height: theme.thumbnailImageHeight,
  width: 'auto',
  maxWidth: theme.thumbnailImageHeight,
});

export default ProductImagesContainer;
