import React from 'react';
import { styled, StyledProps } from '@glitz/react';

function CartIcon({ compose }: StyledProps) {
  return (
    <styled.Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      css={compose()}
      fill="none"
    >
      <path
        d="M13.3334 4L6.00008 11.3333L2.66675 8"
        stroke="#FFF"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </styled.Svg>
  );
}

export default styled(CartIcon);
