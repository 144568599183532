import React, { useState } from 'react';

import { styled, theme } from '../Theme';
import { UpdateCart } from '../Cart/Cart';
import IProductPage from './Models/ProductPageModel.interface';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';
import { media, pseudo } from '@glitz/core';
import { LoadingCircle } from '../Shared/Icons';
import CtaButton from '../Shared/CtaButton/CtaButton';
import PlusToggleIcon from '../Shared/Icons/PlusToggleIcon';
import MinusToggleIcon from '../Shared/Icons/MinusToggleIcon';
import Divider from '../Shared/Divider/Divider';
import { mediaQuery } from '../Theme/mediaQueries';
import VariationModel from '../KexVariation/Models/VariationModel.interface';
import CheckIcon from '../Shared/Icons/CheckIcon';

type AttributeArea = {
  productPage: IProductPage;
  variation: VariationModel;
  bookingNumber: string;
  planogramInputQuantity: number;
};

function AttributeArea({
  variation: {
    totalPrice,
    eanCode,
    size,
    price,
    articleNumber,
    inStock,
    quantity,
    unitPrice,
    code,
    recommendedPrice,
  },
  productPage,
  bookingNumber,
  planogramInputQuantity,
}: AttributeArea) {
  const [inputQuantity, setInputQuantity] = useState<number>(
    planogramInputQuantity
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [itemAdded, setItemAdded] = useState(false);

  const {
    translations: {
      'productPage/addToCart': addToCartLabel,
      'productPage/articleNumber': articleNumberLabel,
      'productPage/eanCode': eanCodeLabel,
      'productPage/size': productSizeLabel,
      'productPage/inStock': inStockLabel,
      'productPage/notInStock': notInStockLabel,
      'productPage/addedToCart': addedToCartLabel,
      'common/quantity': quantityLabel,
      'common/perPackage': perPackageLabel,
      'common/unitPrice': unitPriceLabel,
      'common/piece': pieceLabel,
      'productPage/recommendedPrice': recommendedPriceLabel,
      'common/currency': currencyLabel,
    },
    languageRoute,
  } = useAppSettingsData();

  const onInputChange = (value: string) => {
    if (Number(value) < 0) {
      setInputQuantity(1);
      return;
    }
    setInputQuantity(Number(value));
  };

  const onInputBlur = (numb: string) => {
    Number(numb) < 1 && setInputQuantity(1);
  };

  const addToCart = () => {
    const updated = UpdateCart(
      code,
      inputQuantity,
      languageRoute,
      bookingNumber ? bookingNumber : '',
      setIsLoading
    );
    if (updated) {
      setItemAdded(true);
      setTimeout(() => {
        setItemAdded(false);
      }, 2000);
    }
  };

  return (
    <AttrContainerWrapper>
      <BrandAndCategory>{productPage.mainCategory}</BrandAndCategory>
      <Heading>{productPage.displayName}</Heading>
      <StockStatus>
        {inStock ? (
          <>
            <StockStatusCircle data-instock={inStock} />
            <StockStatusText>{inStockLabel}</StockStatusText>
          </>
        ) : (
          <>
            <StockStatusCircle />
            <StockStatusText>{notInStockLabel}</StockStatusText>
          </>
        )}
      </StockStatus>
      <div>
        <StyledDivider />
        {articleNumber && (
          <>
            <InformationListItem>
              <ListItemTitle>{articleNumberLabel}</ListItemTitle>{' '}
              <ListItem>{articleNumber}</ListItem>
            </InformationListItem>
            <StyledDivider />
          </>
        )}
        {eanCode && (
          <>
            <InformationListItem>
              <ListItemTitle>{eanCodeLabel}</ListItemTitle>{' '}
              <ListItem>{eanCode}</ListItem>
            </InformationListItem>
            <StyledDivider />
          </>
        )}
        {size && (
          <>
            <InformationListItem>
              <ListItemTitle>{productSizeLabel}</ListItemTitle>{' '}
              <ListItem>{size}</ListItem>
            </InformationListItem>
            <StyledDivider />
          </>
        )}
        {recommendedPrice && (
          <>
            <InformationListItem>
              <ListItemTitle>{recommendedPriceLabel}</ListItemTitle>{' '}
              <ListItem>{recommendedPrice}</ListItem>
            </InformationListItem>
            <StyledDivider />
          </>
        )}
      </div>
      {inStock && (
        <>
          <PriceContainer>
            <MainPriceContainer>
              <Price>{totalPrice}</Price>
              <PerPackageText>/{perPackageLabel}</PerPackageText>
            </MainPriceContainer>
            <div>
              <QuantityPriceWrapper>
                <QuantityTextBold>
                  {quantityLabel}/{perPackageLabel}:&nbsp;
                </QuantityTextBold>
                <QuantityText>
                  {quantity}
                  {pieceLabel}
                </QuantityText>
              </QuantityPriceWrapper>
              <QuantityPriceWrapper>
                <QuantityTextBold>{unitPriceLabel}:&nbsp;</QuantityTextBold>
                <QuantityText>
                  {unitPrice}
                  {currencyLabel}
                </QuantityText>
              </QuantityPriceWrapper>
            </div>
          </PriceContainer>
          <div>
            <AddToCart>
              <QuantitySelector>
                <MinusButton
                  disabled={inputQuantity === 1}
                  onClick={() => setInputQuantity(inputQuantity - 1)}
                >
                  <StyledMinusIcon />
                </MinusButton>
                <QuantityInput
                  type={'number'}
                  min={'1'}
                  max={'99'}
                  value={inputQuantity}
                  onChange={e => onInputChange(e.target.value)}
                  onBlur={e => onInputBlur(e.target.value)}
                />
                <button onClick={() => setInputQuantity(inputQuantity + 1)}>
                  <StyledPlusIcon />
                </button>
              </QuantitySelector>
              <AddToCartButton onClick={() => addToCart()}>
                <ButtonText data-isloading={isLoading || itemAdded}>
                  {addToCartLabel}
                </ButtonText>
                {isLoading && <StyledLoadingCircle isLoading={isLoading} />}
                {itemAdded && !isLoading && (
                  <ItemAddedText>
                    {addedToCartLabel}
                    <StyledCheckIcon />
                  </ItemAddedText>
                )}
              </AddToCartButton>
            </AddToCart>
          </div>
        </>
      )}
      <ProductAttrListWrapper></ProductAttrListWrapper>
    </AttrContainerWrapper>
  );
}

const StyledLoadingCircle = styled(LoadingCircle, {
  color: theme.white,
  height: theme.gamma,
  margin: { xy: 'auto' },
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
});

const ButtonText = styled.span({
  transition: {
    property: 'all',
    duration: '0.2s',
    timingFunction: 'linear',
  },
  ...pseudo([':nth-child(n)[data-isloading="true"]'], {
    visibility: 'hidden',
    opacity: 0,
  }),
});

const Heading = styled.h1({
  padding: {
    bottom: theme.spacing(5),
  },
  font: {
    size: theme.psi,
    weight: theme.fontWeight.bold,
  },
  lineHeight: theme.lineHeight.tight,
  letterSpacing: theme.letterSpacing.wider,
  color: theme.black,
  ...media(theme.mediaQuery.mediaMinLarge, {
    font: {
      size: '32px',
    },
    padding: { bottom: theme.zeta },
  }),
});

const BrandAndCategory = styled.p({
  margin: { top: theme.spacing(6), bottom: theme.spacing(2) },
  color: theme.middleGray,
  textTransform: 'uppercase',
  font: {
    size: theme.beta,
    weight: theme.fontWeight.normal,
  },
  ...media(mediaQuery.mediaMinLarge, {
    marginTop: theme.spacing(8),
    fontSize: theme.gamma,
  }),
});

const StockStatus = styled.div({
  display: 'flex',
  alignItems: 'center',
  margin: { bottom: theme.spacing(8) },
  ...media(mediaQuery.mediaMinLarge, {
    marginBottom: theme.spacing(6),
  }),
});

const StyledCheckIcon = styled(CheckIcon, {
  height: '18px',
  width: '18px',
  marginLeft: '8px',
});

const ItemAddedText = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.white,
  height: theme.gamma,
  margin: { xy: 'auto' },
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
});

const StockStatusCircle = styled.div({
  borderRadius: '50%',
  width: '8px',
  height: '8px',
  backgroundColor: theme.notInStockRed,
  marginRight: theme.spacing(2),
  ...pseudo([':nth-child(n)[data-instock="true"]'], {
    backgroundColor: theme.inStockGreen,
  }),
});

const StockStatusText = styled.span({
  color: theme.black,
  font: { size: theme.alpha, weight: theme.fontWeight.bold },
  textTransform: 'uppercase',
});

const InformationListItem = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: { x: theme.spacing(4) },
});

const ListItemTitle = styled.span({
  color: theme.middleGray,
  fontSize: theme.headerMainLink,
  textTransform: 'uppercase',
});

const ListItem = styled.span({
  fontSize: theme.beta,
  color: theme.black,
});

const StyledDivider = styled(Divider, {
  width: '100%',
  backgroundColor: theme.grayLine,
  margin: { y: theme.delta },
  ...media(theme.mediaQuery.mediaMaxMedium, {
    margin: { y: theme.delta },
  }),
});

const PriceContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  margin: {
    top: theme.theta,
    bottom: theme.psi,
  },
});

const MainPriceContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
});

const QuantityPriceWrapper = styled.div({
  fontSize: '15px',
  display: 'flex',
  justifyContent: 'end',
  ...pseudo(':not(:last-child)', { marginBottom: theme.spacing(3) }),
});

const Price = styled.span({
  font: {
    size: theme.nearGreat,
    weight: theme.fontWeight.bold,
  },
  marginTop: theme.spacing(2),
  lineHeight: theme.lineHeight.tight,
  letterSpacing: theme.letterSpacing.wider,
  color: theme.black,
});

const PerPackageText = styled.p({
  marginTop: theme.spacing(4),
  fontSize: '17px',
  marginLeft: '6px',
});

const QuantityText = styled.span({ color: theme.middleGray });

const QuantityTextBold = styled.span({
  fontWeight: theme.fontWeight.bold,
  color: theme.black,
});

const AddToCart = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const QuantitySelector = styled.div({
  backgroundColor: theme.white,
  borderRadius: '100px',
  display: 'flex',
  justifyContent: 'space-between',
  width: '35%',
  alignItems: 'center',
  padding: { x: theme.spacing(5), y: '14px' },
  ...media(mediaQuery.mediaMinLarge, {
    padding: { y: '18px' },
  }),
});

const MinusButton = styled.button({
  ':disabled': {
    cursor: 'default',
    opacity: 0.6,
  },
});

const QuantityInput = styled.input({
  width: theme.spacing(7),
  backgroundColor: theme.white,
  textAlign: 'center',
  MozAppearance: 'textfield', // hide number arrows Firefox
  ...pseudo(['::-webkit-outer-spin-button', '::-webkit-inner-spin-button'], {
    WebkitAppearance: 'none', // hide number arrows Chrome, Safari, Edge, Opera
    margin: { xy: 0 },
  }),
});

const StyledPlusIcon = styled(PlusToggleIcon, {
  height: '20px',
  width: '20px',
  fill: theme.black,
});

const StyledMinusIcon = styled(MinusToggleIcon, {
  height: '20px',
  width: '20px',
  fill: theme.black,
});

const AddToCartButton = styled(CtaButton, {
  position: 'relative',
  width: '65%',
  fontSize: theme.moreLarge,
  textAlign: 'center',
  lineHeight: '1.3em',
  padding: { x: theme.spacing(5), y: '15px' },
  marginLeft: theme.spacing(5),
  ...media(theme.mediaQuery.mediaMinLarge, {
    padding: { y: theme.spacing(5) },
  }),
});

const ProductAttrListWrapper = styled.div({
  whiteSpace: 'pre-line',
  textAlign: 'center',
});

const AttrContainerWrapper = styled.div({
  width: '100%',
  ...media(theme.mediaQuery.mediaMinSmall, {
    padding: { x: theme.none },
  }),
});

export default AttributeArea;
