import React from 'react';
import { styled, theme } from '../../Theme';
import { StyleOrStyleArray } from '@glitz/type';

type PropType = {
  children: React.ReactNode;
  style?: StyleOrStyleArray;
};

function ContentContainer({ children, style, ...rest }: PropType) {
  return (
    <Container css={style} {...rest}>
      {children}
    </Container>
  );
}

export default ContentContainer;

const Container = styled.div({
  width: '100%',
  maxWidth: theme.contentMaxWidth,
  margin: {
    x: 'auto',
  },
  padding: {
    y: theme.none,
  },
});
